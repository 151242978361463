import portafolio from '../pdf/portafolio.pdf';

const Modal = () => {
    return (
        <div className="modal fade" id="downloadModal" tabIndex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="downloadModalLabel">Portafolio</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <span>¿Desea descargar el portafolio de productos?</span>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <a href={portafolio} type="button" className="btn btn-success" rel="noopener noreferrer nofollow" target="_blank" download="portafolio_agrik.pdf">Descargar</a>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Modal