import { useEffect } from "react";
import Contactanos from "./components/Contactanos";
import Productos from "./components/Productos";
import Nosotros from "./components/Nosotros";
import Whatsapp from "./components/Whatsapp";
import Footer from "./components/Footer";
import Inicio from "./components/Inicio";
import Modal from "./components/Modal";
import Aos from "aos";


function App() {
  useEffect(() => {
    Aos.init({duration: 3000});
  },[]);

  return (
    <>
      <div className="d-flex flex-column gap-4">
        <Inicio/>
        <Productos/>
        <Nosotros/>
        <Contactanos/>
        <Whatsapp/>
      </div>
      <Modal/>
      <Footer/>
    </>
  );
}

export default App;
