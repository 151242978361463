import imgInit from '../img/portada.png'
import Header from './Header';

const Inicio = () => {
    return (
        <section className=".section-container">
            <div className="card" id='Inicio'>
                <picture>
                    <img src={imgInit} className='img-fluid' alt='imagen de portada'/>
                </picture>
                <div className='container-overlay-data'>
                    <Header/>
                    <div className='section-data'>
                        <h1 className="card-title title text-center">
                            <p className='mb-0 text-decoration'>Productos y soluciones</p>
                            <p className='mb-0 mr-top-negative'>para la agricultura</p>
                        </h1>
                        <div className='contSubtitle'>
                            <p className='subtitle mb-0'>
                                incrementa el <span className='text-decoration'><strong>rendimiento</strong></span> de tu siembra
                            </p>
                        </div>
                        {/*<div>
                            <a className='btn-cotizacion' href='#Contactanos'>QUIERO UNA COTIZACIÓN</a>
                        </div>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Inicio