import logo from '../img/logo.png';


const Header = () => {
    return (
        <nav className="navbar navbar-expand-lg header-top">
            <div className="container-fluid">
                <a className="navbar-brand" href="#Inicio">
                    <img className='img-logo' src={logo} alt="logo agrik" width="300" height="100"/>
                 </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerHeader" aria-controls="navbarTogglerHeader" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse navbar-collapse-header" id="navbarTogglerHeader">
                    <div className='d-flex'>
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 nav-underline ">
                            <li className="nav-item">
                                <a className="nav-link a-header" aria-current="page" href="#Inicio">Inicio</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link a-header" href="#Productos">Productos</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link a-header" href="#Nosotros">Acerca de Agrik</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link a-header a-header-contactanos" href="#Contactanos">Contáctanos</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header