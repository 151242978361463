import contactanos from "../img/contactanos/contactanos.png";

const Contactanos = () => {
    return (
        <section className="section-contactanos overflow-hidden">
            <div id="Contactanos" data-aos="fade-right">
                <div className="section-contact">
                    <img className='img-fluid' src={contactanos} alt="contactanos agrik" />
                </div>
            </div>
        </section>
    );
}

export default Contactanos