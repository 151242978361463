import servicios from '../img/productos/servicios.png';
import servicios1 from '../img/productos/servicios1.png';
import servicios2 from '../img/productos/servicios2.png';
import descarga from '../img/productos/download.png';
import Cards from './Cards';
import MobileImg from '../hooks/MobileImg';

const Productos = () => {
    return (
        <section className="all-section-container overflow-hidden">
            <div id="Productos" data-aos="fade-right">
                <div className="section-productos">
                    <MobileImg servicios={servicios} servicios1={servicios1} servicios2={servicios2}/>
                    <Cards />
                    <a href=":javascript" type="button" data-bs-toggle="modal" data-bs-target="#downloadModal">
                        <img className='img-fluid' src={descarga} alt="download agrik" />
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Productos