

const Nosotros = () => {
    return (
        <section id="Nosotros" className="section-nosotros overflow-hidden">
            <div data-aos="fade-left">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div>
                                <h2 className="title2">Acerca de AGRIK</h2>
                            </div>
                            <div>
                                <h4>Misión</h4>
                                <p className="subtitle">
                                    Distribuir productos de calidad, precios accesibles, brindar atención y servicio a nuestros clientes, brindar asesoría, promover el desarrollo agrario.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="sub-nosotros subtitle">
                                <p className="mb-0">
                                    Nacemos en el año 2020 a raíz de la necesidad en el mercado guatemalteco de productos confiables y de costo accesible para el amigo agricultor.
                                    Debido al incremento de la participación de nuestra marca, ampliamos operaciones en Centroamérica a través de nuestros distribuidores.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Nosotros