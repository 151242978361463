import { FaWhatsapp} from 'react-icons/fa';

const Whatsapp = () => {

    return(
        <>
            <a href="https://wa.me/+50257483808" rel="noopener noreferrer nofollow" target="_blank">
                <FaWhatsapp className="logo-whatsapp z-50" size={60}/>
            </a>
            <a href="https://wa.me/+50250423699" rel="noopener noreferrer nofollow" target="_blank">
                <FaWhatsapp className="logo-whatsapp2 z-50" size={60}/>
            </a>
        </>
    )
}

export default Whatsapp