import { useState, useEffect } from "react";

const MobileImg = ({servicios, servicios1, servicios2}) => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
    
      const handleResize = () => {
        setWidth(window.innerWidth);
      };

    if (width <= 800){
        return (<div className='d-flex flex-wrap mb-5'>
                    <img className='img-fluid' src={servicios1} alt="servicios agrik" />
                    <img className='img-fluid' src={servicios2} alt="servicios agrik" />
                </div>)
    } else {
        return (<img className='img-fluid' src={servicios} alt="servicios agrik" />)
    }
       
}

export default MobileImg