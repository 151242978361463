import video1 from '../img/portadaProd1.mp4';
import portada1 from '../img/portadaProd1.png'
import video2 from '../img/portadaProd2.mp4';
import portada2 from '../img/portadaProd2.png'
import video3 from '../img/portadaProd3.mp4';
import portada3 from '../img/portadaProd3.png'

const Cards = () => {
    return (
        <>
            <div className="d-flex justify-content-center flex-wrap gap-3">
                <div className="card card-prods">
                    <div className="card-header card-header-prods">
                        <h5 className="card-title">Sembradoras Manuales</h5>
                    </div>
                    <div className="card-body card-body-prods">
                        <video poster={portada1} className="w-100 h-100 object-fit-cover card-img video-init" autoPlay loop muted>
                            <source src={video1} type="video/mp4" />
                        </video>
                    </div>
                    <div className="card-footer card-footer-prods text-body-secondary">
                        <a href="https://bit.ly/SembradorasManuales" rel="noopener noreferrer nofollow" target="_blank" className="nav-link a-header a-view-more">Ver más</a>
                    </div>
                </div>
                <div className="card card-prods">
                    <div className="card-header card-header-prods">
                        <h5 className="card-title">Motocultivadores</h5>
                    </div>
                    <div className="card-body card-body-prods">
                        <video poster={portada2} className="w-100 h-100 object-fit-cover card-img video-init" autoPlay loop muted>
                            <source src={video2} type="video/mp4" />
                        </video>
                    </div>
                    <div className="card-footer card-footer-prods text-body-secondary">
                        <a href="https://bit.ly/MotocultivadoresAGRIK" rel="noopener noreferrer nofollow" target="_blank" className="nav-link a-header a-view-more">Ver más</a>
                    </div>
                </div>
                <div className="card card-prods">
                    <div className="card-header card-header-prods">
                        <h5 className="card-title">Peletizadoras</h5>
                    </div>
                    <div className="card-body card-body-prods">
                        <video poster={portada3} className="w-100 h-100 object-fit-cover card-img video-init" autoPlay loop muted>
                            <source src={video3} type="video/mp4" />
                        </video>
                    </div>
                    <div className="card-footer card-footer-prods text-body-secondary">
                        <a href="https://bit.ly/PeletizadorasAGRIK" rel="noopener noreferrer nofollow" target="_blank" className="nav-link a-header a-view-more">Ver más</a>
                    </div>
                </div>
            </div>
            <div className='w-100 mt-5'>
                <div className='d-flex justify-content-center'>
                    <a href="https://bit.ly/CanalAGRIK" rel="noopener noreferrer nofollow" target="_blank" className="nav-link a-header a-view-more a-view-more-prods">Videos de productos</a>
                </div>
            </div>
        </>
    );
}

export default Cards