import {FaFacebookF, FaMapMarkerAlt, FaInstagram} from 'react-icons/fa';
import {AiFillPhone, AiOutlineCopyright} from 'react-icons/ai';

const Footer = () => {

    return(
        <section className='mb-2'>
            <div className='mx-4'>
                <div className="row">
                    <div className="col-lg-10 col-sm-12 d-flex gap-3 footer-info">
                        <span className='d-flex gap-2'>
                            <FaMapMarkerAlt size={18}/>
                            <span>3a. Avenida 1-97 Zona 2 Villa Canales</span>
                        </span>
                        <span className='separator'> | </span>
                        <span className='d-flex gap-2'>
                            <AiFillPhone size={18}/>
                            <span>
                                <a href='tel:+50257483808'>(+502) 5748-3808</a>
                            </span>
                            <span> o </span>
                            <span>
                                <a href='tel:+50250423699'>(+502) 5042-3699</a>
                            </span>
                        </span>
                    </div>
                    <div className="col-lg-2 col-sm-12 footer-social">
                        <div className='d-flex flex-row-reverse gap-2'>
                            <a href="https://www.facebook.com/AgrikGT/" rel="noopener noreferrer nofollow" target="_blank">
                                <FaFacebookF size={18}/>
                            </a>
                            <a href="https://www.instagram.com/agrikgt/" rel="noopener noreferrer nofollow" target="_blank">
                                <FaInstagram size={18}/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 d-flex gap-4 justify-content-center footer-copy'>
                        <span><AiOutlineCopyright size={15}/> COPYRIGHT 2023 </span>
                        <span className='separator'> | </span>
                        <span>
                            <a href="#Inicio">AGRIK</a>
                        </span>
                        <span className='separator'> | </span>
                        <span>
                            <a href="https://www.yetiadvisers.com/" rel="noopener noreferrer nofollow" target="_blank">POWERED BY YETI ADVISERS</a>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer